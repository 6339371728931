<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
          width="375"
        >
          <p
            class="mb-0 font-40 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Password Reset
          </p>
        </v-card>
      </v-card>
      <v-container class="pt-52 pb-207">
        <v-card flat color="transparent" width="560" class="border-r-0 ma-auto">
          <v-form class="px-10 py-8" ref="form">
            <div class="d-flex mb-2">
              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                hide-details="auto"
                autocomplete="off"
                type="email"
                flat
                solo
                outlined
                class="form-input border-r-0"
                placeholder="Email Address*"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdi-email-outline</v-icon
                  >
                </template>
              </v-text-field>
            </div>
            <div class="d-flex justify-end mb-2">
              <router-link to="/login">
                <a
                  class="font-16 line-h-25 font-weight--regular text-color-secondary-200"
                >
                  Go Back
                </a>
              </router-link>
            </div>
            <div class="d-flex justify-end mt-6">
              <v-btn
                :ripple="false"
                color="transparent"
                class="sell-your-ticket-submit height-59 width-320 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
                elevation="0"
                @click="validate"
                :loading="loading"
              >
                Send Password Reset Link
                <img
                  class="ml-2 mt-1"
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-container>
    </div>
    <the-footer />
    <!-- VERIFICATION LINK SENT DIALOGUE -->
    <v-dialog persistent v-model="verificationDialog" width="800">
      <v-card>
        <v-card-title class="headline white" primary-title>
          <v-icon class="mr-2" large color="primary"
            >mdi-checkbox-marked-circle</v-icon
          >
          A password reset link has been sent to your email account
        </v-card-title>

        <v-card-text>
          Please click on the link that has just been sent to your email account
          to reset the password, please check spam just in case you didnt find
          the email in inbox
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text to="/"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import { getToken, getLoggedIn } from "@/utils/auth";
export default {
  name: "PasswordResetEmail",
  data: () => ({
    verificationDialog: false,
    loading: false,
    valid: true,
    form: {
      email: "",
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  components: { TheHeader, TheFooter },
  mounted() {
    if (getToken() && getLoggedIn()) {
      this.$router.push("/account");
    }
  },
  methods: {
    validate: function () {
      if (this.$refs.form.validate()) {
        this.sendPasswordResetLink();
      }
    },
    reset: function () {
      this.$refs.form.reset();
    },
    resetValidation: function () {
      this.$refs.form.resetValidation();
    },
    sendPasswordResetLink: async function () {
      this.loading = true;
      await this.$store
        .dispatch("auth/SEND_PASSWORD_RESET_LINK", this.form)
        .then((res) => {
          if (res.result) this.verificationDialog = true;
        });
      this.loading = false;
    },
  },
};
</script>
