<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
          width="375"
        >
          <p
            class="mb-0 font-40 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Password Reset
          </p>
        </v-card>
      </v-card>
      <v-container class="pt-52 pb-207">
        <v-card flat color="transparent" width="560" class="border-r-0 ma-auto">
          <v-form class="px-10 py-8" ref="form">
            <div class="d-flex mb-2">
              <v-text-field
                v-model="form.password"
                :rules="rules.password"
                hide-details="auto"
                autocomplete="off"
                type="password"
                flat
                solo
                outlined
                class="form-input border-r-0"
                placeholder="Password*"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdi-lock</v-icon
                  >
                </template>
              </v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                v-model="form.password_confirmation"
                :rules="[rules.password_confirmation, passwordConfirmationRule]"
                hide-details="auto"
                autocomplete="off"
                type="password"
                flat
                solo
                outlined
                class="form-input border-r-0"
                placeholder="Confirm Password*"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdi-lock</v-icon
                  >
                </template>
              </v-text-field>
            </div>
            <div class="d-flex justify-end mb-2">
              <router-link to="/login">
                <a
                  class="font-16 line-h-25 font-weight--regular text-color-secondary-200"
                >
                  Login
                </a>
              </router-link>
            </div>
            <div class="d-flex justify-end mt-6">
              <v-btn
                :ripple="false"
                :disabled="loading"
                color="transparent"
                class="sell-your-ticket-submit height-59 width-320 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
                elevation="0"
                @click="validate"
                :loading="loading"
              >
                Reset Password
                <img
                  class="ml-2 mt-1"
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-container>
    </div>
    <!-- PASSWORD UPDATE CONFIRM DIALOGUE -->
    <v-dialog persistent v-model="resetPasswordDialog" width="800">
      <v-card>
        <v-card-title class="headline white" primary-title>
          <v-icon class="mr-2" large color="primary"
            >mdi-checkbox-marked-circle</v-icon
          >
          Your password is successfully updated
        </v-card-title>

        <v-card-text>
          Please visit the login page and login with this updated password.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text to="/login"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <the-footer />
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import { getToken, getLoggedIn } from "@/utils/auth";
export default {
  name: "PasswordResetForm",
  data: () => ({
    resetPasswordDialog: false,
    loading: false,
    valid: true,
    response: null,
    form: {
      password_confirmation: null,
      password: null,
    },
    rules: {
      password_confirmation: (v) => !!v || "Password confirmation is required",
      password: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters",
      ],
      required: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 6) || "Password must have 6+ characters",
        // v => {
        //   const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])(?=.{6,})/;
        //   return (
        //     pattern.test(v) ||
        //     "Min. 6 characters with at least one capital letter, one small letter, a number and a special character."
        //   );
        // }
        // passwordRules: [
        //     v => !!v || 'Password is required',
        //     v => (v && v.length >= 5) || 'Password must have 5+ characters',
        //     v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
        //     v => /(?=.*\d)/.test(v) || 'Must have one number',
        //     v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
        // ]
      ],
    },
  }),
  components: { TheHeader, TheFooter },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Password must match";
    },
  },
  mounted() {
    if (getToken() && getLoggedIn()) {
      this.$router.push("/account");
    }
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    validate: function () {
      if (this.$refs.form.validate()) {
        this.resetPassword();
      }
    },
    reset: function () {
      this.$refs.form.reset();
    },
    resetValidation: function () {
      this.$refs.form.resetValidation();
    },
    resetPassword: async function () {
      this.form.token = this.$route.params.token;
      await this.$store
        .dispatch("auth/RESET_PASSWORD", this.form)
        .then((res) => {
          if (res.result) {
            this.resetPasswordDialog = true;
          }
        });
    },
  },
};
</script>
